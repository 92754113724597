<div
  id="quiz-question-{{ uniqueId }}-{{ question.id }}"
  class="flex flex-col gap-6 bg-white rounded-m shadow-sm p-6">
  <div class="flex justify-between">
    <h4>Question &#x2116;{{ index + 1 }}</h4>
    <p data-testid="question-score-text" class="text-gray-700">
      {{ question.score }} Points
    </p>
  </div>
  <div>
    <h3 data-testid="question-text">{{ question.text }}</h3>
    <p class="text-gray-500">Not answered yet</p>
  </div>
  <div>
    <p class="text-gray-500">
      @if (question.single) {
        Select one of the following
      } @else {
        Select several options
      }
    </p>
    <div class="flex flex-col gap-2">
      @for (variant of question.variants; track variant.id) {
        <button
          data-testid="variant-button"
          class="px-3 py-2 rounded-xs border"
          [class.bg-primary-25]="variant.id | isSelectedPipe: selectedOptions"
          (click)="chooseOption(variant)">
          <div
            data-testid="variant-conteiner"
            class="flex justify-between items-center">
            <p
              data-testid="variant-text"
              [class.text-primary-700]="
                variant.id | isSelectedPipe: selectedOptions
              ">
              {{ variant.text }}
            </p>
            @if (variant.id | isSelectedPipe: selectedOptions) {
              <mat-icon
                data-testid="check-circle-icon"
                color="accent"
                class="!w-4 !h-4 !leading-none !text-base">
                check_circle
              </mat-icon>
            }
          </div>
        </button>
      }
    </div>
  </div>
</div>
