<div
  data-testid="quiz-results-popup"
  mat-dialog-header
  class="flex flex-col gap-3 px-6 pt-5">
  <div class="flex justify-between w-full">
    <h2>Quiz results</h2>
    <mat-icon
      data-testid="close-btn"
      class="material-icons-outlined"
      mat-dialog-close>
      close
    </mat-icon>
  </div>
  <div>
    <p
      data-testid="attempt-status"
      class="rounded-lg w-min text-xs py-1.5 px-2.5 cat-badge-status-{{
        data.attempt.status
      }}">
      {{ data.attempt.status }}
    </p>
    <p data-testid="start-time-text" class="text-gray-700">
      Start Time: {{ data.attempt.start_time | dateFormat }}
    </p>
    <p data-testid="end-time-text" class="text-gray-700">
      Finished Time:
      {{ data.attempt.end_time ? (data.attempt.end_time | dateFormat) : '-' }}
    </p>
  </div>
</div>

<mat-dialog-content>
  <div class="flex flex-col gap-4">
    <div class="flex gap-2">
      <div class="basis-1/3 border border-gray-200 rounded-sm p-3">
        <p class="text-gray-700 pb-3">Time spent</p>
        <h3 data-testid="time-spend-text">
          {{ timeSpend | secondsToTime: true }}
        </h3>
      </div>
      <div class="basis-1/3 border border-gray-200 rounded-sm p-3">
        <p class="text-gray-700 pb-3">Correct</p>
        <h3 data-testid="correct-answers-txt">{{ correntAnswers }}</h3>
      </div>
      <div class="basis-1/3 border border-gray-200 rounded-sm p-3">
        <p class="text-gray-700 pb-3">Failed</p>
        <h3 data-testid="failed-answers-txt">{{ failedAnswers }}</h3>
      </div>
    </div>
    <cat-ai-quiz-navigation
      data-testid="quiz-navigation"
      [questions]="data.questions"
      [uniqueId]="uniqueId"
      [showAnswers]="true"></cat-ai-quiz-navigation>
    <cat-ai-questions-list
      [questions]="data.questions"
      [answers]="data.attempt.answers"
      [uniqueId]="uniqueId"></cat-ai-questions-list>
  </div>
</mat-dialog-content>

<mat-dialog-actions [align]="'end'">
  <button data-testid="back-to-all-training" mat-flat-button mat-dialog-close>
    Back to training
  </button>
  @if (
    data.attempt.status === StatusEnum.Completed ||
    data.attempt.status === StatusEnum.Passed
  ) {
    <button
      data-testid="certificare-btn"
      mat-flat-button
      color="accent"
      [mat-dialog-close]="data.attempt.certificate">
      Certificate
    </button>
  } @else {
    <button
      data-testid="retake-quiz-btn"
      mat-flat-button
      color="accent"
      [mat-dialog-close]="true">
      Retake quiz
    </button>
  }
</mat-dialog-actions>
