@if (loadingState) {
  <div
    class="flex flex-col bg-gray-24 border border-gray-200 rounded-md p-6 gap-6">
    <div class="flex flex-col gap-1.5">
      <label
        class="block font-semibold leading-3 w-fit"
        [class.sceleton]="loadingState">
        Question &#x2116; 1
      </label>
      <mat-form-field class="w-full" [class.sceleton]="loadingState">
        <input data-testid="question-title" matInput />
      </mat-form-field>
    </div>
    <div class="flex flex-col gap-4">
      <label
        class="block font-semibold leading-3 w-fit"
        [class.sceleton]="loadingState">
        Options
      </label>

      <button
        data-testid="add-question/option-btn"
        mat-button
        color="accent"
        class="place-self-start"
        (click)="addOption()"
        [class.sceleton]="loadingState">
        + Add option
      </button>
    </div>
    <hr class="text-gray-300" [class.sceleton]="loadingState" />
    <div class="flex gap-6">
      <div class="flex flex-col gap-1.5" [class.sceleton]="loadingState">
        <label
          data-testid="type-dropdown"
          class="block font-semibold leading-3">
          Type
        </label>
        <cat-ai-selector
          [options]="questionTypeOptions"
          wrapperClasses="!w-[200px]" />
      </div>

      <div class="flex flex-col gap-1.5" [class.sceleton]="loadingState">
        <label class="block font-semibold leading-3">Score</label>
        <mat-form-field class="w-[100px]">
          <input data-testid="score-input" matInput />
        </mat-form-field>
      </div>

      <button
        data-testid="delete-btn"
        mat-button
        color="warn"
        class="ml-auto self-end"
        [class.sceleton]="loadingState">
        <mat-icon class="material-icons-outlined">delete</mat-icon>
        Delete
      </button>
    </div>
  </div>
} @else if (form && index !== null) {
  <form
    [formGroup]="form"
    class="flex flex-col bg-gray-24 border border-gray-200 rounded-md p-6 gap-6">
    <div class="flex flex-col gap-1.5">
      <label class="block font-semibold leading-3 w-fit">
        Question &#x2116; {{ index + 1 }}
      </label>
      <mat-form-field class="w-full">
        <input
          data-testid="question-title-input"
          matInput
          formControlName="text" />
      </mat-form-field>
    </div>
    <div class="flex flex-col gap-4">
      <label class="block font-semibold leading-3 w-fit">Options</label>
      @if (form.controls.variants.value; as variants) {
        @if (form.controls.single.value) {
          <mat-radio-group class="cat-radio-vertical">
            <div
              cdkDropList
              [cdkDropListData]="variants"
              (cdkDropListDropped)="drop($event)"
              class="flex flex-col gap-4">
              @for (variant of variants; track $index) {
                <div cdkDrag class="flex items-center">
                  <mat-radio-button
                    data-testid="radio-btn"
                    [value]="variant.id"
                    [checked]="variant.correct"
                    (change)="
                      changeSingleCorrentVariant($index)
                    "></mat-radio-button>
                  <ng-container
                    *ngTemplateOutlet="
                      textInput;
                      context: { $implicit: variant.text, index: $index }
                    " />
                </div>
              }
            </div>
          </mat-radio-group>
        } @else {
          <div
            cdkDropList
            [cdkDropListData]="variants"
            (cdkDropListDropped)="drop($event)"
            class="flex flex-col gap-4">
            @for (variant of variants; track $index) {
              <div cdkDrag class="flex items-center">
                <mat-checkbox
                  data-testid="checkbox-input"
                  class="mr-4"
                  [value]="variant.id + ''"
                  [checked]="variant.correct"
                  (change)="changeCorrentVariant($index)"></mat-checkbox>
                <ng-container
                  *ngTemplateOutlet="
                    textInput;
                    context: { $implicit: variant.text, index: $index }
                  "></ng-container>
              </div>
            }
          </div>
        }
        <ng-template #textInput let-value let-index="index">
          <mat-form-field class="w-full">
            <input
              data-testid="option-title-input"
              matInput
              [value]="value"
              #variantText
              (input)="changeVariantText(index, variantText.value)" />
          </mat-form-field>

          <div class="flex gap-4 ml-4">
            <mat-icon cdkDragHandle class="text-gray-500">
              drag_indicator
            </mat-icon>
            <mat-icon
              data-testid="delete-btn"
              (click)="removeOption(index)"
              color="warn">
              delete_outline
            </mat-icon>
          </div>
        </ng-template>
      }

      <button
        data-testid="add-question/option-btn"
        mat-button
        color="accent"
        class="place-self-start"
        (click)="addOption()">
        + Add option
      </button>
    </div>
    <hr class="text-gray-300" />
    <div class="flex gap-6">
      <div class="flex flex-col gap-1.5">
        <label class="block font-semibold leading-3">Type</label>
        <cat-ai-selector
          data-testid="type-dropdown"
          [options]="questionTypeOptions"
          formControlName="single"
          wrapperClasses="!w-[200px]"></cat-ai-selector>
      </div>

      <div class="flex flex-col gap-1.5">
        <label class="block font-semibold leading-3">Score</label>
        <mat-form-field class="w-[100px]">
          <input data-testid="score-input" matInput formControlName="score" />
        </mat-form-field>
      </div>

      <button
        data-testid="delete-btn"
        mat-button
        color="warn"
        class="ml-auto self-end"
        (click)="remove.emit()">
        <mat-icon class="material-icons-outlined">delete</mat-icon>
        Delete
      </button>
    </div>
  </form>
}
