<div class="flex flex-col gap-6 p-6">
  <div class="flex justify-between">
    <cat-ai-search (search)="updateSearch($event)"></cat-ai-search>
    <button
      data-testid="create-new-training-btn"
      mat-flat-button
      color="accent"
      (click)="create()">
      + Create new training
    </button>
  </div>
  <div class="flex gap-3">
    <cat-ai-selector
      label="Select department"
      [options]="departments$ | async"
      (change)="changeDepartment($event)"
      [resetOption]="true"></cat-ai-selector>
    <cat-ai-selector
      label="Select job category"
      [options]="jobCategories$ | async"
      (change)="changeJobCategory($event)"
      [resetOption]="true"></cat-ai-selector>
  </div>
  <cat-ai-trainings-list
    [data]="data$ | async"
    [loading]="loading"
    (scrollDown)="onScrollDown()"></cat-ai-trainings-list>
</div>
