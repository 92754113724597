<div
  data-testid="training-material-popup"
  mat-dialog-header
  class="flex justify-between gap-3 px-6 py-5 items-center">
  <h2 data-testid="training-material-header">Training material</h2>
  <mat-icon
    data-testid="close-btn"
    class="material-icons-outlined"
    mat-dialog-close>
    close
  </mat-icon>
</div>
<mat-dialog-content>
  <formly-form [form]="form" [fields]="fields" [model]="model"></formly-form>
</mat-dialog-content>

<mat-dialog-actions [align]="'end'">
  <button
    data-testid="cancel-btn"
    mat-flat-button
    mat-dialog-close
    color="basic">
    Cancel
  </button>
  <button
    data-testid="add-btn"
    mat-flat-button
    color="accent"
    [mat-dialog-close]="model.material">
    Add
  </button>
</mat-dialog-actions>
