<div
  data-testid="edit-quiz-popup-header"
  mat-dialog-header
  class="flex justify-between px-6 pt-6">
  <h2>
    @if (data && data.training && data.training.id) {
      Edit quiz
    } @else {
      New Quiz
    }
  </h2>

  <mat-icon
    data-testid="close-btn"
    class="material-icons-outlined"
    mat-dialog-close>
    close
  </mat-icon>
</div>
<mat-dialog-content class="!max-h-[80vh]">
  <div class="flex gap-6">
    <!-- QUIZ FORM -->
    <div
      class="bg-gray-50 rounded-md p-4 flex flex-col gap-6 sticky top-0 h-min">
      <formly-form
        [form]="form"
        [fields]="fields"
        [model]="model"
        class="[&>formly-field>formly-group]:flex [&>formly-field>formly-group]:flex-col [&>formly-field>formly-group]:gap-6"></formly-form>
      <button
        data-testid="generate-with-ai-button"
        mat-flat-button
        color="fancy"
        (click)="generateQuestions()"
        [loading]="generating">
        <mat-icon>auto_awesome</mat-icon>
        Generate with AI
      </button>
    </div>
    <!-- GENERATED QUIZ FIELDS -->
    <div class="flex-grow flex flex-col gap-2.5">
      @if (generating) {
        <cat-ai-question-form [loadingState]="true"></cat-ai-question-form>
        <cat-ai-question-form [loadingState]="true"></cat-ai-question-form>
        <cat-ai-question-form [loadingState]="true"></cat-ai-question-form>
      } @else {
        @for (
          questionForm of questionForms.controls;
          track $index;
          let last = $last
        ) {
          <cat-ai-question-form
            [index]="$index"
            [form]="questionForm"
            (remove)="remove($index)"></cat-ai-question-form>
          <ng-container
            *ngTemplateOutlet="
              addQuestionBtn;
              context: { $implicit: $index }
            "></ng-container>
        } @empty {
          <ng-container *ngTemplateOutlet="addQuestionBtn"></ng-container>
        }
      }

      <ng-template #addQuestionBtn let-index>
        <button
          data-testid="add-question-btn"
          (click)="addQuestion(index)"
          mat-button
          color="accent"
          class="place-self-start">
          + Add Question
        </button>
      </ng-template>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions [align]="'end'" class="!pt-1">
  <button
    data-testid="cancel-btn"
    mat-flat-button
    color="basic"
    mat-dialog-close
    class="!min-w-32">
    Cancel
  </button>
  <button
    data-testid="create/save-btn"
    mat-flat-button
    color="accent"
    (click)="create()"
    class="!min-w-32">
    @if (data && data.training && data.training.id) {
      Save
    } @else {
      Create
    }
  </button>
</mat-dialog-actions>
